import { FC, useMemo, useCallback, PropsWithChildren } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import useExperiment from 'lux/hooks/useExperiment';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import EmployerPageNavBar from 'lux/pages/EmployerSimpleEdit/components/EmployerPageNavBar';
import EmployerPageTabs from 'lux/pages/EmployerSimpleEdit/components/EmployerPageTabs';
import { EmployerPageTab } from 'lux/pages/EmployerSimpleEdit/components/employerTabsCommon';
import useCurrentTab from 'lux/pages/EmployerSimpleEdit/components/useCurrentTab';

interface UseEmployerPageTabs {
    (): {
        TabsControl: FC;
        FullScreenControl: FC<PropsWithChildren>;
        showContentForTab: (tab: EmployerPageTab) => boolean;
        tabsLayoutEnabled: boolean;
        isFullscreenLayout: boolean;
    };
}

const useEmployerPageTabs: UseEmployerPageTabs = () => {
    const { currentTab, setCurrentTab } = useCurrentTab();
    const isMobileView = [Breakpoint.XS, Breakpoint.S].includes(useBreakpoint());

    const userType = useSelector((state) => state.userType);
    const isApplicantOrAnonymous = [UserType.Anonymous, UserType.Applicant, UserType.NedoUser].includes(userType);
    const isExp = useExperiment('brandy_employer_page_tabs', isApplicantOrAnonymous);

    const TabsControl = useCallback(
        () =>
            isExp ? (
                <EmployerPageTabs isMobileView={isMobileView} activeItemId={currentTab} onChange={setCurrentTab} />
            ) : null,
        [currentTab, isExp, isMobileView, setCurrentTab]
    );

    const FullScreenControl = useCallback(
        (props: PropsWithChildren) => <EmployerPageNavBar onChange={setCurrentTab} {...props} />,
        [setCurrentTab]
    );

    const showContentForTab = useCallback((tab: EmployerPageTab) => !isExp || currentTab === tab, [currentTab, isExp]);

    return useMemo(
        () => ({
            TabsControl,
            FullScreenControl,
            showContentForTab,
            tabsLayoutEnabled: isExp,
            isFullscreenLayout: isExp && isMobileView && currentTab !== EmployerPageTab.Description,
        }),
        [TabsControl, FullScreenControl, showContentForTab, isExp, isMobileView, currentTab]
    );
};

export default useEmployerPageTabs;
