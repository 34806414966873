import { Tabs, Tab, Button, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import { EmployerPageTab } from 'lux/pages/EmployerSimpleEdit/components/employerTabsCommon';

interface EmployerPageTabsProps {
    activeItemId: EmployerPageTab;
    onChange: (newTab: string) => void;
    isMobileView: boolean;
}

const TrlKeys = {
    [EmployerPageTab.Description]: 'employer.view.tab.description',
    [EmployerPageTab.Vacancies]: 'employer.view.tab.vacancies',
    activeVacancies: 'employer.view.tab.vacancies.active',
};

const EmployerPageTabs: TranslatedComponent<EmployerPageTabsProps> = ({
    isMobileView,
    activeItemId,
    onChange,
    trls,
}) => {
    const vacanciesCount = useSelector((state) => state.vacanciesGroupsByRegion.count);

    if (isMobileView) {
        // На XS активатор выводим только на главной
        if (activeItemId !== EmployerPageTab.Description) {
            return null;
        }
        // Вместо вкладок кнопка "Активные вакансии"
        return (
            <>
                <VSpacing default={32} />
                <Button
                    mode="primary"
                    style="accent"
                    postfix={`${vacanciesCount || ''}`}
                    stretched
                    onClick={() => onChange(EmployerPageTab.Vacancies)}
                >
                    {trls[TrlKeys.activeVacancies]}
                </Button>
            </>
        );
    }
    return (
        <>
            <VSpacing default={24} />
            <Tabs activeItemId={activeItemId} onChange={onChange}>
                {Object.values(EmployerPageTab).map((tab) => (
                    <Tab
                        id={tab}
                        key={tab}
                        postfix={tab === EmployerPageTab.Vacancies ? `${vacanciesCount || ''}` : undefined}
                    >
                        {trls[TrlKeys[tab]]}
                    </Tab>
                ))}
            </Tabs>
        </>
    );
};

export default translation(EmployerPageTabs);
