import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import RawUserContent from 'lux/components/RawUserContent';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    defaultDescription: 'employer.edit.simple.defaultDescription',
    addDescription: 'employer.edit.simple.addDescription',
    editDescription: 'employer.edit.simple.editDescription',
    noDescription: 'employer.no.description.text',
};

interface DefaultDescriptionProps {
    toggleShowDescriptionEditor?: () => void;
    editMode?: boolean;
}

const DefaultDescription: TranslatedComponent<DefaultDescriptionProps> = ({
    trls,
    toggleShowDescriptionEditor,
    editMode,
}) => {
    const descriptionText = useSelector((state) => state.employerInfo.description);
    const hasDescription = !!descriptionText;

    const defaultDescriptionText = editMode ? trls[TrlKeys.defaultDescription] : trls[TrlKeys.noDescription];

    return (
        <>
            {hasDescription ? (
                <Text size={TextSize.Large} data-qa="company-description-text">
                    <RawUserContent content={descriptionText} />
                </Text>
            ) : (
                defaultDescriptionText
            )}
            <VSpacing base={6} />
            {editMode && (
                <div className="company-description-edit-button">
                    <Gap right>
                        <Button
                            onClick={toggleShowDescriptionEditor}
                            data-qa="edit-company-description"
                            kind={ButtonKind.Primary}
                            appearance={hasDescription ? undefined : ButtonAppearance.Outlined}
                        >
                            {hasDescription ? trls[TrlKeys.editDescription] : trls[TrlKeys.addDescription]}
                        </Button>
                    </Gap>
                </div>
            )}
            <VSpacing base={6} />
        </>
    );
};

export default translation(DefaultDescription);
