import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { VSpacing } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import HeaderTitle from 'lux/components/Employer/HeaderTitle';
import EmployerPageTitle, { TileMode } from 'lux/components/Employer/PageTitle';
import Sidebar from 'lux/components/Employer/Sidebar';
import VacanciesGroupsByRegion from 'lux/components/Employer/VacanciesGroupsByRegion';
import AddWidget from 'lux/components/EmployerConstructor/AddWidget';
import EmployerReviewsWidgetBig from 'lux/components/EmployerReviews/WidgetBig';
import EmployerReviewsWidgetSmall from 'lux/components/EmployerReviews/WidgetSmall';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import useToggleState from 'lux/hooks/useToggleState';
import { useSelector } from 'lux/modules/useSelector';

import DefaultDescription from 'lux/pages/EmployerSimpleEdit/components/DefaultDescription';
import EditDescription from 'lux/pages/EmployerSimpleEdit/components/EditDescription';
import { EmployerPageTab } from 'lux/pages/EmployerSimpleEdit/components/employerTabsCommon';
import useEmployerPageTabs from 'lux/pages/EmployerSimpleEdit/components/useEmployerPageTabs';

const setAutoAddWidget = makeSetStoreField('employerConstructorAutoAddWidget');

const EmployerSimpleEdit: FC = () => {
    const editMode = useSelector((state) => state.employerInfo.canEditDescription && !state.employerPageIsViewMode);
    const employerName = useSelector((state) => state.employerInfo.name);
    const hasConstructorService = useSelector((state) => state.employerConstructor.hasService);
    const employerHasDivisions = useSelector((state) => state.employerHasDivisions);
    const employerAccepted = useSelector((state) => state.employerInfo.accepted);

    const [showDescriptionEditor, toggleShowDescriptionEditor] = useToggleState(false);
    const dispatch = useDispatch();
    const isZarplata = useIsZarplataPlatform();

    const { showContentForTab, TabsControl, FullScreenControl, isFullscreenLayout, tabsLayoutEnabled } =
        useEmployerPageTabs();

    const currentBreakpoint = useBreakpoint();
    const isXs = currentBreakpoint === Breakpoint.XS;
    const isLargeScreen = [Breakpoint.M, Breakpoint.L].includes(currentBreakpoint);
    const showHeaderReviewsWidget = employerAccepted && isLargeScreen;
    const showBottomReviewsWidget = employerAccepted && !editMode;

    const isEmployerConstructorAllowed = hasConstructorService || !employerHasDivisions || isZarplata;
    const isAddEmployerConstructorWidgetAllowed =
        (hasConstructorService || !employerHasDivisions) && editMode && !isZarplata && !isXs;

    if (isFullscreenLayout) {
        return (
            <EmployerPageTitle mode={TileMode.View} employerName={employerName}>
                <FullScreenControl>
                    {showContentForTab(EmployerPageTab.Vacancies) && <VacanciesGroupsByRegion hasChameleon />}
                </FullScreenControl>
            </EmployerPageTitle>
        );
    }

    return (
        <EmployerPageTitle mode={editMode ? TileMode.EditDescription : TileMode.View} employerName={employerName}>
            <div className="employer-constructor-xs-hidden">
                <Gap bottom />
            </div>
            <ColumnsWrapper>
                <Column xs="4" s="8" m="3" l="3">
                    <Sidebar editMode={editMode} showConstructorButton={isEmployerConstructorAllowed} />
                </Column>
                <Column xs="4" s="8" m="9" l="11">
                    <div className="sticky-container">
                        <div className="company-header">
                            <HeaderTitle isMainHeader={isLargeScreen} />
                            {showHeaderReviewsWidget && <EmployerReviewsWidgetSmall />}
                        </div>
                        <TabsControl />
                        {showContentForTab(EmployerPageTab.Description) && (
                            <>
                                <Gap top>
                                    {showDescriptionEditor ? (
                                        <EditDescription toggleShowDescriptionEditor={toggleShowDescriptionEditor} />
                                    ) : (
                                        <DefaultDescription
                                            toggleShowDescriptionEditor={toggleShowDescriptionEditor}
                                            editMode={editMode}
                                        />
                                    )}
                                </Gap>
                                {isAddEmployerConstructorWidgetAllowed && (
                                    <Gap top bottom>
                                        <AddWidget
                                            widgets={[]}
                                            disableToggleOpened={true}
                                            onClick={({ type }) => {
                                                dispatch(setAutoAddWidget(type));
                                                dispatch(
                                                    push(
                                                        `/employer/constructor?from=add_${type.toLowerCase()}&hhtmFromLabel=add_${type.toLowerCase()}`
                                                    )
                                                );
                                            }}
                                        />
                                    </Gap>
                                )}
                                {showBottomReviewsWidget && <EmployerReviewsWidgetBig />}
                            </>
                        )}
                        {!editMode && showContentForTab(EmployerPageTab.Vacancies) && (
                            <>
                                {tabsLayoutEnabled && <VSpacing default={24} />}
                                <VacanciesGroupsByRegion hasChameleon={tabsLayoutEnabled} />
                            </>
                        )}
                    </div>
                </Column>
            </ColumnsWrapper>
        </EmployerPageTitle>
    );
};

export default EmployerSimpleEdit;
