import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { push } from 'connected-react-router';

import { EmployerPageTab } from 'lux/pages/EmployerSimpleEdit/components/employerTabsCommon';

const DEFAULT_TAB = EmployerPageTab.Description;

const routeToEmployerTabMap: Partial<Record<string, EmployerPageTab>> = {
    '/vacancies': EmployerPageTab.Vacancies,
};
const getRouteTab = (route: string | undefined) => (route && routeToEmployerTabMap[route]) || DEFAULT_TAB;

interface UseCurrentTab {
    (): { currentTab: EmployerPageTab; setCurrentTab: (newTab: string) => void };
}

const useCurrentTab: UseCurrentTab = () => {
    const match = useRouteMatch<{ 0: string; 1: string }>();
    const subRoute = match.params[1];
    const baseUrl = subRoute ? match.url.split(subRoute)[0] : match.url;
    const currentTab = getRouteTab(subRoute);

    const dispatch = useDispatch();
    const setCurrentTab = useCallback(
        (newTab: string) => {
            const newRoute = Object.entries(routeToEmployerTabMap).find(([, tab]) => tab === newTab)?.[0] || '';
            dispatch(push(baseUrl + newRoute, null, { cancelFetchingData: true }));
        },
        [baseUrl, dispatch]
    );

    return useMemo(() => ({ currentTab, setCurrentTab }), [currentTab, setCurrentTab]);
};

export default useCurrentTab;
