import { PropsWithChildren, FC } from 'react';

import { NavigationBar, VSpacing, GridLayout } from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { useSelector } from 'lux/modules/useSelector';

import { EmployerPageTab } from 'lux/pages/EmployerSimpleEdit/components/employerTabsCommon';

interface EmployerPageNavBarProps {
    onChange: (newTab: string) => void;
}

const EmployerPageNavBar: FC<PropsWithChildren<EmployerPageNavBarProps>> = ({ onChange, children }) => {
    const employerName = useSelector((state) => state.employerInfo.name);
    return (
        <>
            <VSpacing default={8} />
            <NavigationBar
                title={employerName}
                left={{ icon: ChevronLeftOutlinedSize24, onClick: () => onChange(EmployerPageTab.Description) }}
            >
                <GridLayout>{children}</GridLayout>
            </NavigationBar>
        </>
    );
};

export default EmployerPageNavBar;
