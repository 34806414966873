import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    errors: {
        TEXT_TOO_LONG: 'employer.edit.description.error.text_too_long',
        TEXT_TOO_SHORT: 'employer.edit.description.error.text_too_short',
        TOO_MANY_CAPS: 'employer.edit.description.error.too_many_caps',
        UNKNOWN: 'employer.edit.description.error.unknown',
    },
    success: 'employer.edit.description.success',
};

export type ErrorType = keyof typeof TrlKeys.errors;

const SaveDescriptionError: TranslatedComponent<{ type: ErrorType }> = ({ trls, type }) => (
    <>{trls[TrlKeys.errors[type]]}</>
);
export const employerSaveDescriptionError = {
    Element: translation(SaveDescriptionError),
    kind: 'error',
    autoClose: true,
};

const SaveDescriptionSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.success]}</>;
export const employerSaveDescriptionSuccess = {
    Element: translation(SaveDescriptionSuccess),
    kind: 'ok',
    autoClose: true,
    dataQa: 'save-description-success',
};
